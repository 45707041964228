$(function(e) {
    AOS.init();

    // progress bar swiper//
        let bar = $('#progress');
        let tick;
        let time = 2;
            
        const startProgressbar = () =>{
            resetProgressbar();
            percentTime = 0;
            isPause = false;
            tick = setInterval(interval, 30);
        }

        const interval = () => {
        if (isPause === false) {
                percentTime += 1 / (time + 0.1);
                bar.css({
                width: percentTime + "%"
            });
            if (percentTime >= 100) {
                swiperMainHome.slideNext();
                startProgressbar();
            }
        }
        }

        const resetProgressbar = () => {
            bar.css({
                width: 0 + '%'
            });
            clearTimeout(tick);
        }
    // end prgoress bar swiper //
    
    // hamburger
    $('#hamburger').click(function(){
        $(this).toggleClass('open');
        $('#mainNav').toggleClass('open');
        $('body').toggleClass('open');
    });
    
    // home slider
    const swiperMainHome = new Swiper ('#swiper-container-main-home', {
        loop: true,
        parallax: true,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        loop: true,
        on: {
            progress: function () {
                startProgressbar();
            },
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        pagination: {
            el: '#swiper-pagination-home-main',
            type: 'custom',
            clickable: true,
            renderCustom: function (swiper, current, total) {
                function numberAppend(d) {
                    return (d < 10) ? '0' + d.toString() : d.toString();
            }
                // return numberAppend(current) + numberAppend(total);
                return `<span class="swiper-pagination-current">${numberAppend(current)}</span>` + `<span class="swiper-pagination-total">${numberAppend(total)}</span>`;
            }
   
        },

    })

    // top info 
    $('.top-info__close').click(() => {
        $('.top-info').hide();
    })

    // footer year
    const date = new Date();
    const year = date.getFullYear();
    $('#year').text(year);

});
